import styled from 'styled-components';

import { Text, Flex, Box } from 'core/ui';
import { hex2Rgba } from 'core/styles/utils';
import { InstantBookIcon } from 'images/badges/rv/Instantbook';

const thumnailAspectRatio = 228 / 295; // height / width

export const Card = styled.div`
  position: relative;
  width: 100%;
  min-height: ${props => props.noHeight ? 'inherit' : '385px'};
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.17);
  transition: box-shadow ease-in-out 20ms;
  color: ${({ theme }) => theme.colors.darkBlue || "#0a2239"};
  text-decoration: none;

  &:hover {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.34);
  }
`;

export const SingleThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${thumnailAspectRatio * 100}%;
  background-color: ${({ theme }) => theme.colors.veryLightGrey || "#e5e5e5"};

  img {
    width: 100%;
    height: auto;
    position: absolute;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &.blank {
      opacity: 0;
    }
  }
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.veryLightGrey ||"#e5e5e5"};

  ${({ theme }) => theme.medium`
    .image-slider-pagination-button {
      display: none;
    }

    &:hover {
      .image-slider-pagination-button {
        display: inline-block;
      }
    }
  `}

  ul {
    margin: 0;
    cursor: ${({ isSwipeable }) => (isSwipeable ? 'grab' : 'pointer')};
  }

  img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

export const ThumbnailHeadlineOverlay = styled.div`
  padding: 0.75rem 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #ffffff;
  background-image: ${({ theme }) =>
    `linear-gradient(to top, ${
      theme.colors.darkBlue || `rgba(10,34,57, 0)`
    }, rgba(10, 34, 57, 0) 100%, rgba(0, 0, 0, 0))`};

  h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: 1.56;
    letter-spacing: 0.2px;
  }

  h5 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    line-height: 1.29;
    letter-spacing: 0.1px;
  }
`;

export const ThumbnailFavoriteOverlay = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 2;
  cursor: pointer;
`;

export const MetaWrapper = styled(Flex)`
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-direction: row;
  flex-wrap: wrap;

  ${({ theme }) => theme.medium`
    flex-direction: column;
    flex-wrap: nowrap;
  `};
`;

export const RatingInstantBookWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.4rem;
  margin-top: -0.4rem;
  min-height: 22px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.medium`
    flex-direction: row;
    margin-top: 0.1rem;
    margin-bottom: 0.8rem;
    width: 100%;
  `};
`;

export const RatingsWrapper = styled(Box)`
  margin-top: -0.6rem;
  text-align: right;
  order: 2;

  ${({ isInstantBook }) => !isInstantBook && 'padding-top: 1.0rem;'}

  svg {
    font-size: 0.5rem;
  }

  ${({ theme }) => theme.medium`
    margin-top: 0;
    text-align: left;
    order: 0;
    padding-top: 0;

    svg {
      font-size: 1rem;
    }
  `};
`;

export const MetaInnerWrapper = styled(Box)`
  flex: 0 1 auto;
  padding: 0 1rem;
  width: 100%;

  ${({ theme }) => theme.medium`
    background-color: ${hex2Rgba(theme.colors.lightGrey || "#e5e5e5", 0.2)};
    padding: 1rem;
    padding-bottom: 1.5rem;
  `};
`;

export const MetaText = styled(Text)`
  font-size: 15px;
  font-weight: 600;
`;

export const RateWrapper = styled.div`
  order: -1;
  flex: 1 0 50%;
  padding-left: 1rem;
  padding-right: 1rem;

  ${({ theme }) => theme.small`
    padding-right: 0;
  `};

  ${({ theme }) => theme.medium`
    order: 0;
    padding-right: 1rem;
    margin-bottom: -.3rem;
    flex: 1 0 auto;
  `};
`;

export const RateText = styled.div`
  display: inline;
  font-size: 23px;
  line-height: 26px;

  ${({ theme }) => theme.medium`
    display: block;
    font-size: 38px;
    line-height: 43px;
  `}
`;

export const RateTextMinimal = styled.div`
  font-size: 30px;
`;

export const RateDescription = styled.div`
  display: inline;
  color: #a8a8a8;
  font-size: 11px;
  line-height: 12px;
  font-weight: 600;
  padding-left: 0.4rem;

  ${({ theme }) => theme.medium`
    display: block;
    font-size: 13px;
    line-height: 15px;
    padding-left: 1.2rem;
  `}
`;

export const StyledInstantBookIcon = styled(InstantBookIcon)`
  width: 10px;

  ${({ theme }) => theme.medium`
    width: 14px;
  `};
`;

export const InstantBookLabel = styled.span`
  display: inline-block;
  font-size: 12px;
  margin-top: 2px;
  vertical-align: top;

  ${({ theme }) => theme.medium`
    display: inline;
    font-size: inherit;
    margin-top: 0;
    vertical-align: top;
  `};
`;

export const HoverLink = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  text-indent: -9999px;
`;

export const MobileBullet = styled.span`
  background-color: ${({ theme }) => theme.colors.lightGrey || "#e5e5e5"};
  display: inline-block;
  border-radius: 50%;
  margin-top: -2px;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  height: 0.25rem;
  width: 0.25rem;
  vertical-align: middle;

  ${({ theme }) => theme.medium`
    display: none;
  `};
`;

export const MetaLocationBox = styled(Box)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 0;

  ${({ theme }) => theme.medium`
    flex: 0 1 auto;
    width: auto;
  `};
`;

export const InstantBookBox = styled(Box)`
  margin-top: 0.3rem;

  ${({ theme }) => theme.medium`
    flex: 1;
    margin-top: 0;
  `};
`;

export const DeliveryBox = styled(Box)`
  order: 12;
  flex: 0 0 100%;
  margin-top: 4px;

  ${({ theme }) => theme.medium`
    order: 0;
    flex: none;
    margin-top: 0;
  `};
`;

export const MetaTextDeliveryStyled = styled.div`
  text-align: left;

  ${({ theme }) => theme.medium`
    text-align: right;
  `}
`;

export const StyledSimpleCard = styled.div`
  position: relative;
  width: 100%;
  background-color: #ffffff;
  color: #414141;
  text-decoration: none;

  a,
  a:visited {
    color: #414141;
    text-decoration: none;
  }

  img {
    width: 100%;
  }

  h3 {
    font-weight: 600;
    font-size: 21px;
    color: #337ac9;
    margin: 10px 0 5px 0;
  }

  h4 {
    font-weight: 600;
    font-size: 14px;
    margin: 0 0 5px 0;
  }

  p {
    display: none;
    @media (min-width: 992px) {
    display: block;
    margin: 0;
    font-size: 14px;
    }
  }

`;
