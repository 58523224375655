import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDevice } from 'core/hooks/useDevice';

import Icon from 'core/ui/Icon';
import NewListingStar from 'images/new-listing-star.svg';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

const NewListingWrapper = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-weight: 400;
  span {
    font-size: ${props => props.mapCard ? '10px' : '15px'};
    vertical-align: top;
  }
  ${props => props.styles};
  
  @media (max-width: 768px) {
    span {
      font-size: ${props => props.mapCard ? '10px' : '12px'};
    }
  }
`;

export default function Ratings({ className, score, styles, numberOfReviews = null, isNewListing = false, mapCard = false }) {
  // 5 stars
  const stars = Math.round(score / 20);
  const { isMobile } = useDevice();

  if (isNewListing) {
    let imgWidth = isMobile ? 13 : 18;
    let imgHeight = isMobile ? 12 : 16;
    if (mapCard) {
      imgWidth = 9;
      imgHeight = 8;
    }

    return (
      <NewListingWrapper className={className} aria-label={`${stars} stars`} data-rating={stars} styles={styles} mapCard={mapCard}>
        <img src={NewListingStar} alt="New Listing" width={imgWidth} height={imgHeight} /> <span>New Listing</span>
      </NewListingWrapper>
    )
  }

  if (stars < 3) {
    return null;
  }

  return (
    <Wrapper className={className} aria-label={`${stars} stars`} data-rating={stars}>
      {[0, 1, 2, 3, 4].map(i => (
        <Icon key={i} icon={[i < stars ? 'fas' : 'far', 'star']} />
      ))}
      {numberOfReviews > 0 &&  <span style={{color: '#8d8d8d'}}>{` (${numberOfReviews})`}</span>}
    </Wrapper>
  );
}

Ratings.defaultProps = {
  className: null,
};

Ratings.propTypes = {
  className: PropTypes.string,
  score: PropTypes.number.isRequired,
};
