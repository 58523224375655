import styled from 'styled-components';

import { mediaQueriesForHide } from './mediaQuery';

export const Container = styled.div`
  padding: 0 16px;

  ${({ theme }) => theme.medium`
    padding: 4px 20px 0 20px;
  `}
`;

/**
 * Converts a CSS hex color value to RGBA.
 * @param {string} hex - Expanded hexadecimal CSS color value.
 * @param {number} alpha - Alpha as a decimal.
 * @returns {string} RGBA CSS color value.
 */
export function hex2Rgba(hex, alpha) {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

/**
 * Hide for screen sizes below the passed in key
 * https://github.com/jxnblk/hidden-styled
 */
const hidden = key => props =>
  (props[key]
    ? {
      [mediaQueriesForHide[key]]: {
        display: 'none',
      },
    }
    : null);

const mobile = hidden('mobile');
const small = hidden('small');
const medium = hidden('medium');
const large = hidden('large');
const xlarge = hidden('xlarge');

export const Hide = styled.div([], mobile, small, medium, large, xlarge);
